<script>
  import { get, sync, call } from 'vuex-pathify'
  import CustomizeVariantCard from '@/components/customize/CustomizeVariantCard'
  import UpgradeConfirmation from '@/components/account/UpgradeConfirmation'
  import UpgradeSuccess from '@/components/account/UpgradeSuccess'
  import screen from '@/helpers/screen'

  export default {
    components: {
      CustomizeVariantCard,
      UpgradeConfirmation,
      UpgradeSuccess,
    },
    data() {
      return {
        submitting: false,
        confirmed: false,
        upgradeSuccessfulVisible: false,
        error: null,
        upgradeSuccessfulTimeout: null,
        response: null,
        confirmationModalOpen: false,
        featureList: ['Save $20 each year', 'Customize products', 'More Choice+ access'],
      }
    },
    computed: {
      ...get('customize', [
        'selectedBox',
        'upgradeSeasons',
        'upgradeCost',
        'filteredSelectedBoxUpsellVariants',
      ]),
      ...get('feature', ['flags']),
      ...get('config', ['currentSeason']),
      ...get('choicePlusCart', ['cartQuantity']),
      ...sync('subscribe', ['selectedPlanHandle']),
      screen,
      subscriptionId() {
        return this.$route.params.subscriptionId
      },
      nextRoute() {
        return {
          name: 'SubscribeInsiders',
          params: {
            ...this.$route.params,
          },
        }
      },
      isAnnualUpsellIncentive() {
        return this.flags['annual-upsell-incentive'] ?? false
      },
      bannerTitle() {
        if (this.isAnnualUpsellIncentive) {
          return `Want your ${this.currentSeason} Box for FREE?`
        } else {
          return 'Upgrade to customize your ENTIRE box!'
        }
      },
      bannerDescription() {
        if (this.isAnnualUpsellIncentive) {
          return `Get your ${this.currentSeason} Box (an up to $400+ value) for free when you upgrade to an Annual Membership! You’ll also get to <span class="font-bold">customize EVERY product</span> in your ${this.currentSeason} Box today, and you’ll save an extra $5 every season!`
        } else {
          return `Upgrade to an Annual Membership to customize all 6 full-size products in your box AND save $20 per
            year!`
        }
      },
    },
    watch: {
      confirmed() {
        clearTimeout(this.upgradeSuccessfulTimeout)
        if (this.confirmed) {
          this.confirmationModalOpen = false
          this.upgradeSuccessfulVisible = true
          this.selectedPlanHandle = 'annually'
          this.upgradeSuccessfulTimeout = setTimeout(() => {
            this.upgradeSuccessfulVisible = false
            this.$navigate({
              name: 'SubscribeInsiders',
              params: { ...this.$route.params, subscriptionId: this.response.id },
            })
          }, 2000)
        } else {
          this.upgradeSuccessfulVisible = false
        }
      },
    },
    methods: {
      ...call('subscribe', ['upgradeSubscription']),
      ...call('customize', ['load']),
      upgrade() {
        this.confirmationModalOpen = true
      },
      async upgradeImmediately() {
        this.submitting = true
        try {
          this.response = await this.upgradeSubscription({
            subscriptionId: this.subscriptionId,
          })
          await this.load()
          this.confirmed = true
        } catch (error) {
          if (error) {
            this.error = error.data.message
          }
        } finally {
          this.submitting = false
        }
      },
      dismiss() {
        this.confirmationModalOpen = false
      },
      navigateToDetails(variantId) {
        this.$navigate({
          name: 'CustomizeUpgradeDetails',
          params: {
            ...this.$route.params,
            variantId: variantId,
          },
        })
      },
    },
  }
</script>

<template>
  <div>
    <div v-if="$route.name === 'CustomizeUpgradeDetails' && !screen.md" key="details">
      <RouterView />
    </div>

    <BaseContainer v-else max-width="xl" class="flex flex-col items-center">
      <div
        class="flex flex-wrap lg:grid lg:grid-cols-2 lg:grid-rows-1 -mx-6 sm:-mx-9 mb-6 lg:mx-0 lg:mt-6 justify-center bg-white"
      >
        <div
          class="w-full lg:w-auto h-full lg:h-auto lg:col-start-2 lg:row-start-1 grid grid-cols-3 lg:gap-y-2 lg:gap-x-2 bg-dawn lg:pl-2"
        >
          <BaseImage
            v-for="index in 6"
            :key="index"
            :aspect-ratio="1"
            responsive="sm"
            :src="`/static/upgrade/Upgrade${index}.jpg`"
            alt=""
          />
        </div>
        <div
          class="w-full lg:col-start-1 lg:row-start-1 px-4 pt-6 pb-8 flex flex-col items-center justify-center"
        >
          <h2
            class="banner-title font-heading font-semibold text-5xl lg:text-10 text-center mb-6 lg:mb-4 lg:px-14"
            >{{ bannerTitle }}</h2
          >
          <p
            class="text-xs mb-6 lg:mb-4 font-medium text-center leading-snug-tight max-w-96"
            v-html="bannerDescription"
          />
          <BaseButton color="summer" :disabled="submitting" class="w-56 md:w-94" @click="upgrade">
            Upgrade Me
          </BaseButton>
        </div>
      </div>

      <div class="mt-6 lg:mt-8 text-xs text-center">
        <BaseLinkStyled :to="nextRoute">
          {{
            isAnnualUpsellIncentive ? 'No thanks, continue without offer' : 'No thanks, maybe later'
          }}
        </BaseLinkStyled>
      </div>
      <BaseDivider color="black" :weight="1" class="my-6 lg:my-8 max-w-90 lg:max-w-full" />

      <component
        :is="screen.md ? 'BaseContainer' : 'div'"
        :max-width="screen.md ? 'md' : undefined"
        :class="`${screen.md ? '' : 'px-4'}`"
      >
        <BasePanelWarning v-if="error" class="my-2">
          {{ error }}
        </BasePanelWarning>
        <div class="flex flex-col items-center">
          <div v-if="filteredSelectedBoxUpsellVariants && filteredSelectedBoxUpsellVariants.length">
            <div class="my-6">
              <h3 class="font-heading font-semibold text-xxl text-center">
                Want to customize your ENTIRE box?
              </h3>
              <h4 class="text-xs text-center"> Upgrade to start customizing now! </h4>
            </div>
            <ul class="grid justify-center gap-x-3 gap-y-8 grid-cols-2 md:grid-cols-4">
              <li
                v-for="variant in filteredSelectedBoxUpsellVariants"
                :key="variant.id"
                class="w-34 2xs:w-42 xs:w-45 max-w-full"
              >
                <CustomizeVariantCard
                  :variant="variant"
                  display-only
                  has-cta
                  @details="navigateToDetails(variant.id)"
                />
              </li>
            </ul>
            <BaseDivider v-if="!screen.md" color="black" :weight="1" class="mt-10 w-full" />
            <button
              type="button"
              class="px-3 mt-10 mb-8 w-full rounded-full bg-white h-15 max-w-100 mx-auto flex items-center shadow-sm"
              @click="upgrade"
            >
              <BaseButtonPill color="summer" height="tall" type="display"
                ><span class="font-medium">Upgrade</span></BaseButtonPill
              >
              <span class="ml-4 w-2/3 font-medium text-2xs">
                Upgrade to an Annual Membership to access box customizations.
              </span>
            </button>
          </div>
          <p class="text-3xs font-medium text-primary-light text-left lg:text-center mb-8">
            By clicking “Upgrade Me”, you authorize us to terminate your existing Seasonal
            Membership and charge you {{ upgradeCost(subscriptionId) }} for the next
            {{ upgradeSeasons(subscriptionId) }} quarters on your new Annual Membership. As soon as
            the upgrade process completes, you'll see a newly created Annual Membership in the "My
            Memberships" section of your account portal. Additional shipping and handling costs
            apply for boxes shipping to Canada, AK, HI, and US Territories. Applicable sales taxes
            will be applied upon submission. If you have any questions, please review this FAQ or
            reach out to our customer care team at:
            <BaseLinkStyled font-weight="medium" href="mailto:help@alltrue.com">
              help@alltrue.com
            </BaseLinkStyled>
          </p>

          <Portal to="modal">
            <UpgradeConfirmation
              v-if="confirmationModalOpen"
              :submitting="submitting"
              :error="error"
              @upgrade="upgradeImmediately"
              @dismiss="dismiss"
            />
            <UpgradeSuccess v-else-if="upgradeSuccessfulVisible" />
            <RouterView v-else />
          </Portal>
        </div>

        <!-- Loading spinner -->
        <BaseSpinner
          v-if="submitting && !confirmationModalOpen"
          overlay="absolute"
          overlay-color="dawn-lt3"
          class="z-10"
        />
      </component>
      <div v-if="filteredSelectedBoxUpsellVariants && filteredSelectedBoxUpsellVariants.length">
        <BaseDivider color="black" :weight="1" class="max-w-90 lg:max-w-full" />
        <div class="w-full text-center mt-8">
          <BaseLinkStyled :to="nextRoute" class="text-xs">
            No thanks, continue without offer
          </BaseLinkStyled>
        </div>
      </div>
    </BaseContainer>
  </div>
</template>

<style lang="postcss" scoped>
  .banner-title {
    line-height: 1.05;

    @screen lg {
      line-height: 1.1;
    }
  }
</style>
